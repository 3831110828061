/* forms */
(function ($) {
  const OTHER = '';

  const makeForms = function () {
    // make all the file inputs droppable
    $('[data-choice-other]').choiceOther({});
  };
  $(document).ready(makeForms);
  $(document).on('update', makeForms);
  $(document).on('popup', makeForms);

  /**
   *
   * jQuery Plugin that turns an input field of type "file" into a draggable zone and a file list that displays all
   * the files.
   *
   * @param options
   * @returns {*}
   */
  $.fn.choiceOther = function (options) {
    return $(this).each(function () {
      const buildDefaultOptions = function () {
        const opts = $.extend({}, $.fn.choiceOther.defaultOptions);
        return opts;
      };
      const activeOptions = $.extend(buildDefaultOptions(), options);

      const el = $(this);
      const selectField = $(this).find('select');
      const textField = $(this).find('[type="text"]');

      const updateView = function () {
        if (selectField.val() == OTHER) {
          textField.css('display', 'block');

          // if it was initialized with bootstrap class then we remove it
          if (textField.hasClass('hidden')) {
            textField.removeClass('hidden');
          }

          // if the text-val is an address enum, then on change we remove the value such that
          // the choice other is empty to be filled by the user
          if (ADDRESS_ENUMS.includes(textField.val())) {
            textField.val('');
          }
        } else {
          textField.css('display', 'none');
        }
      };

      selectField.on('change', updateView);

      updateView();
    });
  };
  $.fn.choiceOther.defaultOptions = {};
})(jQuery);
