/* forms */
(function ($) {
  const OTHER = '';

  const makeForms = function () {
    // We use data-initialized to mark already setup plz-fields - this is needed because we nowadays have the PLZ form multiple times in a page (SeatStep with C/O)
    if($(this).attr('data-initialized') === undefined){
      $(this).plz({});
    }
  };

  const plzListener = function () {
    $('[data-plz]:not([data-initialized])').on('input', makeForms);
  };

  $(document).ready(plzListener);
  $(document).on('update', plzListener);
  $(document).on('popup', plzListener);

  /**
   * @param options
   * @returns {*}
   */
  $.fn.plz = function (options) {
    return $(this).each(function () {

      const buildDefaultOptions = function () {
        return $.extend({}, $.fn.plz.defaultOptions);
      };
      const activeOptions = $.extend(buildDefaultOptions(), options);

      const el = $(this);
      el.attr('data-initialized', 'true');

      const parentForm = el.closest('form');

      const ortField = parentForm.find(`[data-ort="${el.attr('data-plz')}"]`);
      const sitzField = parentForm.find(`[data-sitz="${el.attr('data-plz')}"]`);
      const kantonField = parentForm.find(`[data-kanton="${el.attr('data-plz')}"]`);

      ortField.css('display', 'none');
      const ortName = ortField.attr('name');
      ortField.attr('name', null);
      ortField.after(
        `<select name="${ortName}" class="form-control" data-ort-select="${el.attr(
          'data-plz'
        )}"></select>`
      );
      const ortSelect = parentForm.find(`[data-ort-select="${el.attr('data-plz')}"]`);

      sitzField.css('display', 'none');
      const sitzName = sitzField.attr('name');
      sitzField.attr('name', null);
      sitzField.after(
        `<select name="${sitzName}" class="form-control" data-sitz-select="${el.attr(
          'data-plz'
        )}"></select>`
      );
      const sitzSelect = parentForm.find(`[data-sitz-select="${el.attr('data-plz')}"]`);

      kantonField.attr('readonly', 'true');

      let selectedPlz = el.val();
      let selectedOrt = ortField.val();
      let selectedSitz = sitzField.val();

      let updateLocalityTimer;

      const restartTimer = function (timer, func, time) {
        // kill old timer, if any exist
        if (timer) {
          window.clearTimeout(timer);
        }

        // start timer for function
        return window.setTimeout(func, time);
      };

      el.on('input', function () {
        selectedPlz = $(this).val();
        updateLocalityTimer = restartTimer(updateLocalityTimer, updateOrt, 500);
      });

      ortSelect.on('change', function () {
        if ($(this).val()) selectedOrt = $(this).val();
        updateSitz();
      });

      sitzSelect.on('change', function () {
        if ($(this).val()) selectedSitz = $(this).val();
        updateKanton();
      });

      const updateOrt = function () {
        const plz = el.val();

        $.ajax({
          dataType: 'json',
          url: PLZ_REQUEST,
          data: { plz },
          success(data) {
            let htmlStr = '';
            let contains = false;
            $.each(data.result, function () {
              if (this.ort === selectedOrt) {
                contains = true;
              }
              htmlStr += `<option value="${this.ort}">${this.ort}</option>`;
            });

            if (data.result.length > 1) {
              htmlStr = `<option value="" disabled selected>${translations.select_option}</option>${htmlStr}`;
            }

            ortSelect.html(htmlStr);
            if (contains) {
              ortSelect.val(selectedOrt);
            }
            updateSitz();
          }
        });
      };

      const updateSitz = function () {
        const plz = el.val();
        const ort = ortSelect.val();

        $.ajax({
          dataType: 'json',
          url: PLZ_REQUEST,
          data: { plz, ort },
          success(data) {
            let htmlStr = '';
            let contains = false;
            $.each(data.result, function () {
              htmlStr += `<option value="${this.seat}" data-kanton="${this.kanton}">${this.seat}</option>`;

              if (this.seat === selectedSitz) {
                contains = true;
              }
            });

            if (data.result.length > 1) {
              htmlStr = `<option value="" disabled selected>${translations.select_option}</option>${htmlStr}`;
            }


            sitzSelect.html(htmlStr);
            if (contains) {
              sitzSelect.val(selectedSitz);
            }
            updateKanton();
          }
        });
      };

      const updateKanton = function () {
        const kantonSelect = parentForm.find(`[data-sitz-select] option[value="${sitzSelect.val()}"]`).attr('data-kanton');
        kantonField.val(kantonSelect);
      };

      // at the start, if the plz was saved previously, then load ort and seat
      if (selectedPlz) {
        updateOrt();
      }
    });
  };
  $.fn.plz.defaultOptions = {};
})(jQuery);
