export default {
  /**
   * Validate the given email address.
   * @param email
   */
  validateEmail(email: string) {
    // valid are: 1.a1@1.aa, 1-a1.aa@1.com, abc@a.com, abc.123@a.com, abc_123@a.com, _1a@1.com
    // invalid are: 1a.a, a.1@, a1.a@a, 1.a1@1.a, 1.@1.com
    return !!email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  },
  /**
   * Require number to start with "+" but only validate Swiss numbers (starting with +417[5-9] and followed by 7 digits).
   * @param number
   */
  validateMobilePhone(number: string): string | null {
    if (null === number.match(/^\+/)) {
      return 'must_start_with';
    }

    return number.match(/^((\+417[5-9][0-9]{7})|(\+(?!41)\d+))$/) === null
      ? 'invalid_format'
      : null;
  },
};
