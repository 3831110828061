const addToDom = require('./modal');

/* forms */
(function ($) {
  const FILE_NAME = 'files[]';

  const makeForms = function () {
    // make all the file inputs droppable
    $('[data-file-upload-field]').fileDrop({});
  };
  $(document).ready(makeForms);
  $(document).on('update', makeForms);
  $(document).on('popup', makeForms);

  /**
   *
   * jQuery Plugin that turns an input field of type "file" into a draggable zone and a file list that displays all
   * the files.
   *
   * @param {array} options Options to pass.
   * @return {*}
   */
  $.fn.fileDrop = function (options) {
    return $(this).each(function () {
      const buildDefaultOptions = function () {
        const opts = $.extend({}, $.fn.fileDrop.defaultOptions);
        return opts;
      };

      const el = $(this);
      const fileField = $(this).find('[type="file"]');
      const valueField = $(this).find('[type="text"]');
      const fileList = el.find('[data-upload-file-list]');

      const multiple = el.attr('data-multiple') !== undefined;
      const images = el.attr('data-images') !== undefined;
      // Allow passing trustee as admin
      const trustee = el.data('trustee');

      let fileArray = [];

      const updateView = function () {
        fileList.html('');
        for (let i = 0; i < fileArray.length; i++) {
          fileList.append(`<li><span>${fileArray[i].name}</span></li>`);
        }
      };

      const addFiles = function (files) {
        if (!files) {
          return;
        }
        // We need to send dropped files to Server
        for (let i = 0; i < files.length; i++) {
          fileArray.push(files[i]);
        }
        // fileField.val('');
        // fileField.data("files", fileArray);
        // $(fileField)[0].files = fileArray;
        updateView();
      };

      const uploadFiles = function () {
        // creating data to send
        const data = new FormData();

        // for all file inputs, we iterate over the added files and add those separately to the FormData object
        for (let i = 0; i < fileArray.length; i++) {
          data.append(FILE_NAME, fileArray[i]);
        }

        if (trustee) {
          data.append('trustee', trustee);
        }

        // Use Ajax to submit form data
        $.ajax({
          url: FILES_URL,
          method: 'POST',
          cache: false,
          processData: false,
          contentType: false,
          type: 'POST',
          data,
          // we want json back
          dataType: 'json',
          // we use this function to add our progress bar handler
          xhr() {
            const myXhr = $.ajaxSettings.xhr();
            if (myXhr.upload) {
              // check if upload property exists
              myXhr.upload.addEventListener(
                'progress',
                (evt) => {
                  if (evt.lengthComputable) {
                    $progress.css('width', `${(evt.loaded / evt.total) * 100}%`);
                  }
                },
                false
              ); // for handling the progress of the upload
            }
            return myXhr;
          },
        })
          // when ajax is finished, we display message and do other stuff
          .done((result) => {
            if (result.status === 'error') {
              alert(result.message);
              fileArray = [];
              updateView();
            } else {
              fileArray = [];
              updateView();
              updateValueField(result.files);
            }
          });
      };
      fileField.on('change', (event) => {
        addFiles($(fileField)[0].files);
        uploadFiles();
      });

      const dropZone = el.find('[data-drop-files]');
      const button = el.find('[data-upload]');
      const chooseLink = dropZone.find('[data-choose-link]');
      const $progress = el.find('[data-progress]');

      button.on('click', (e) => {
        e.preventDefault();
        uploadFiles();
      });

      chooseLink.on('click', (e) => {
        e.preventDefault();
        fileField.click();
      });
      dropZone.on('dragenter', function (e) {
        e.stopPropagation();
        e.preventDefault();
        $(this).addClass('hover');
      });
      dropZone.on('dragleave', function (e) {
        $(this).removeClass('hover');
      });
      dropZone.on('dragover', (e) => {
        e.stopPropagation();
        e.preventDefault();
      });
      dropZone.on('drop', function (e) {
        $(this).removeClass('hover');

        e.preventDefault();
        const { files } = e.originalEvent.dataTransfer;
        addFiles(files);
        uploadFiles();
      });

      const updateList = function () {
        const text = editor.val();
        const evt = $.event('change');
        if (active.text() === text || editor.hasClass('error')) {
          return true;
        }
        const originalContent = active.html();
        active.text(text).trigger(evt, text);
        if (evt.result === false) {
          active.html(originalContent);
        }
      };

      /* gui stuff */
      let guiFiles = [];
      const guiFileList = el.find('[data-file-list]');

      /**
       * adds files to the value field
       *
       * @param {array} files
       */
      const updateValueField = function (files) {
        if (multiple) {
          $.each(files, function () {
            guiFiles.push(this);
          });
        } else if (files.length > 0) {
          guiFiles = [files[files.length - 1]];
        }
        valueField.val(JSON.stringify(guiFiles));

        updateGUI();
      };

      const updateGUI = function () {
        guiFiles = JSON.parse(valueField.val());
        let data = { files: guiFiles };
        if (trustee) {
          data['trustee'] = trustee;
        }
        $.ajax({
          url: images ? IMAGES_INFO : FILES_INFO,
          method: 'POST',
          data,
          dataType: 'html',
          type: 'POST',
          // we use this function to add our progress bar handler
        })
          // when ajax is finished, we display message and do other stuff
          .done((html) => {
            guiFileList.html(html);

            guiFileList.find('[data-remove]').click(function (e) {
              e.preventDefault();
              const newGuiFiles = [];
              const rem = $(this).attr('data-remove');

              $.each(guiFiles, function () {
                if (this != rem) {
                  newGuiFiles.push(this);
                }
              });

              valueField.val(JSON.stringify(newGuiFiles));

              updateGUI();
            });
          });
      };
      updateGUI();
    });
  };
  $.fn.fileDrop.defaultOptions = {};
})(jQuery);
