import { ButtonStateEnum } from '@/enums/ButtonStateEnum';
export default function useButtonState(isLoading, isError, isSuccess) {
  var buttonState = computed(function () {
    if (isLoading.value) {
      return ButtonStateEnum.LOADING;
    }
    if (isError.value) {
      return ButtonStateEnum.ERROR;
    }
    if (isSuccess.value) {
      return ButtonStateEnum.SUCCESS;
    }
    return ButtonStateEnum.DEFAULT;
  });
  return {
    buttonState: buttonState
  };
}