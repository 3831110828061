export function absoluteUrl(path, subDomain) {
  if (/^https?:\/\//.test(path)) {
    return path;
  }
  var origin = window.location.origin;
  if (subDomain !== undefined) {
    return "".concat(origin.replace('://', "://".concat(subDomain, "."))).concat(path);
  }
  return "".concat(origin).concat(path);
}
export function useAbsoluteUrl(path) {
  var value = computed(function () {
    return absoluteUrl(unref(path));
  });
  return {
    value: value
  };
}