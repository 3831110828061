/**
 * Sets the cookie settings.
 *
 * @param {boolean} setting
 */
function setCookieSettings(setting) {
  window.settings.cookie.allowed = setting;
}

window.addEventListener('load', () => {
  if (typeof window.settings !== 'object') {
    window.settings = {};
    window.settings.cookie = {};
    window.settings.cookie.allowed = false;
  } else if (typeof window.settings.cookie !== 'object') {
    window.settings.cookie = {};
    window.settings.cookie.allowed = false;
  }

  window.cookieconsent.initialise({
    palette: {
      popup: {
        background: '#2a2a2a',
      },
      button: {
        background: '#eeeeee',
      },
    },
    domain: window.location.host,
    position: 'bottom-right',
    type: 'opt-in',
    revokable: true,
    content: {
      message: translations.cookie.message,
      dismiss: translations.cookie.dismiss,
      allow: translations.cookie.allow,
      deny: translations.cookie.deny,
      link: translations.cookie.link,
      href: translations.cookie.href,
      policy: translations.cookie.policy,
    },
    onInitialise(status) {
      const { type } = this.options;
      const didConsent = this.hasConsented();
      if (type === 'opt-in' && didConsent) {
        setCookieSettings(true);
      }
      if (type === 'opt-out' && !didConsent) {
        setCookieSettings(false);
      }
    },
    onStatusChange(status, chosenBefore) {
      const { type } = this.options;
      const didConsent = this.hasConsented();
      if (type === 'opt-in' && didConsent) {
        setCookieSettings(true);
      }
      if (type === 'opt-out' && !didConsent) {
        setCookieSettings(false);
      }
    },
    onRevokeChoice() {
      const { type } = this.options;
      if (type === 'opt-in') {
        setCookieSettings(false);
      }
      if (type === 'opt-out') {
        setCookieSettings(true);
      }
    },
  });
});
