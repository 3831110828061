export default class Field {
  name = '';

  label = '';

  translationFile = '';

  value = '';

  type = '';

  placeholder = '';

  // type: Option[]
  options = [];

  disabled = false;

  error = '';

  constructor(
    name,
    label,
    translationFile,
    options = [],
    disabled = false,
    value = '',
    type = 'text',
    placeholder = ''
  ) {
    this.name = name;
    this.label = label;
    this.translationFile = translationFile;
    this.options = options;
    this.disabled = disabled;
    this.value = value;
    this.type = type;
    this.placeholder = placeholder;
  }
}
