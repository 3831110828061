import { translate } from '@/js/frontend/filters';
import useCachedLocale from '@/ts/VueCompositions/UseCachedLocale';

export default function useProcessReopen(processId: number) {
  // redirecting to reopen the process ("Korrekturen vornehmen")
  const { getCachedLocale } = useCachedLocale();

  function reopenProcess(): void {
    if (window.confirm(translate('process', 'alert.reopen'))) {
      window.location.href = `/${getCachedLocale()}/process/${processId}/reopen`;
    }
  }

  return {
    reopenProcess,
  };
}
