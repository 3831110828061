// needed for bootstrap 5 to work
window.bootstrap = require('bootstrap');

global.$ = global.jQuery = $;

function DynamicAdd(el) {
  this.el = $(el);
  this.removePrototype();
  this.applyRemoveDomListeners();
  const that = this;
  this.el.find('[data-add]').click((e) => {
    e.preventDefault();
    that.add();
  });
}

DynamicAdd.prototype.removePrototype = function () {
  this.prototype = this.el.find('[data-prototype]').html();
  this.el.find('[data-prototype]').html('');
};
DynamicAdd.prototype.applyRemoveDomListeners = function () {
  this.el.find('[data-remove]').click(function (e) {
    e.preventDefault();
    $(this).closest('[data-element]').remove();
  });
};
DynamicAdd.prototype.add = function () {
  this.el.append(this.prototype);
  this.applyRemoveDomListeners();
};

// Everything custom we need
const customAppFunctions = function () {
  // revision step
  const updateHasRevision = function () {
    if ($('#appbundle_founding_revision_hasRevision').val() === NO_REVISION) {
      $('#revision-collapse').collapse('hide');
    } else {
      $('#revision-collapse').collapse('show');
    }
  };

  updateHasRevision();
  $('#appbundle_founding_revision_hasRevision').on('change', () => {
    updateHasRevision();
  });

  // reset Identification number field
  const resetIdentificationNumber = function () {
    $('[name="nat_person[identificationNumber]"]').val('');
  };

  $('[name="nat_person[identificationMethod]"]').on('change', resetIdentificationNumber);
  $(document).on('popup', () => {
    $('[name="nat_person[identificationMethod]"]').on('change', resetIdentificationNumber);
  });

  // heimatort
  const updateAllHeimatorte = function () {
    $('#nat-person-origin-list')
      .children()
      .each((index, originEntry) => {
        const hometownEntry = $('input[id$="_hometown"]', originEntry);

        if ($('select[id$="_nationality"]', originEntry).val() == SWITZERLAND) {
          hometownEntry.closest('.form-group').css('display', 'block');
        } else {
          hometownEntry.closest('.form-group').css('display', 'none');
        }
      });
  };
  $('#nat-person-origin-list').on('change', updateAllHeimatorte);
  $(document).on('popup', () => {
    $('#nat-person-origin-list').on('change', updateAllHeimatorte);
    updateAllHeimatorte();
  });
  updateAllHeimatorte();

  // heimatort with personmutations
  const updateHeimatortPersonMutations = function () {
    if ($('[name="mutation_person_new[nationality]"]').val() == SWITZERLAND) {
      $('#mutation_person_new_hometown').closest('.form-group').css('display', 'block');
    } else {
      $('#mutation_person_new_hometown').closest('.form-group').css('display', 'none');
    }
  };
  $('[name="mutation_person_new[nationality]"]').on('change', updateHeimatortPersonMutations);
  $(document).on('popup', () => {
    $('[name="mutation_person_new[nationality]"]').on('change', updateHeimatortPersonMutations);
    updateHeimatortPersonMutations();
  });
  updateHeimatortPersonMutations();

  // ProductChoiceStep
  const updateProductChoiceStep = function () {
    if ($('[name="form[productChoice]"]:checked').val() == PRODUCT_A_B) {
      $('#form_vollmacht').val('0');
      $('#form_vollmacht').closest('.form-group').css('display', 'none');
    } else {
      $('#form_vollmacht').closest('.form-group').css('display', 'block');
    }

    if (
      $('[name="form[productChoice]"]:checked').val() == PRODUCT_A &&
      $('#form_vollmacht').val() == 1
    ) {
      $('#vollmacht_person').css('display', 'block');
    } else {
      $('#vollmacht_person').css('display', 'none');
    }
  };
  $('[name="form[productChoice]"]').on('change', updateProductChoiceStep);
  $('#form_vollmacht').on('change', updateProductChoiceStep);
  updateProductChoiceStep();

  // PersonProtocolStep
  const updatePersonProtocolStepStep = function () {
    if ($('#gv_protocol_anwesenheit').val() == 1) {
      $('#anwesenheitVotes').hide();
    } else {
      $('#anwesenheitVotes').show();
    }

    if ($('#gv_protocol_zustimmung').val() == 1) {
      $('#zustimmungVotes').hide();
    } else {
      $('#zustimmungVotes').show();
    }
  };
  $('#gv_protocol_anwesenheit').on('change', updatePersonProtocolStepStep);
  $('#gv_protocol_zustimmung').on('change', updatePersonProtocolStepStep);
  updatePersonProtocolStepStep();

  const updatePersonProtocolStepStep2 = function () {
    $('#zustimmungVotes')
      .children('div')
      .each(function () {
        const shareId = $(this).data('shareid');
        $(`#zustimmung_label_${shareId}`)
          .find('.zustimmung-label-amount')
          .text($(`#gv_protocol_votes_${shareId}_anwesenheitVotes`).val());
      });
  };
  $('.anwesenheit-votes').on('change', updatePersonProtocolStepStep2);
  updatePersonProtocolStepStep2();

  // ShareCollectionStep
  $(document).on('change', '#form_share_type', () => {
    const form = $('#form_share_type');

    $.ajax({
      data: form.serialize(),
      type: form.attr('method'),
      url: form.attr('action'),
      success() {
        location.reload();
      },
      error(response) {
        // do nothing
      },
    });
  });

  $('#change_to_share_mutation').on('click', () =>
    confirm(translations.share_collection_change_process)
  );

  const updateHasOwnOffice = function () {
    if ($('#form_ownOffice').val() == 1) {
      $('#seat-collapse').collapse('hide');
    } else {
      $('#seat-collapse').collapse('show');
    }
  };

  updateHasOwnOffice();
  $('#form_ownOffice').on('change', () => {
    updateHasOwnOffice();
  });

  const updateHasHrDocDelivery = function () {
    if ($('#hr_step_numberOfHrDocuments').val() == 0) {
      $('#hr-doc-delivery-collapse').collapse('hide');
    } else {
      $('#hr-doc-delivery-collapse').collapse('show');
    }
  };

  updateHasHrDocDelivery();
  $('#hr_step_numberOfHrDocuments').on('change', () => {
    updateHasHrDocDelivery();
  });

  // show Kanton field only if country is chosen as SWITZERLAND
  const updateAddressFormBasedOnCountry = function () {
    if ($('[custom-id="address-collapser"]').val() === SWITZERLAND) {
      $('#auto-address-collapse').collapse('show');
      $('#auto-company-name-collapse').collapse('show');
      $('#address-collapse').collapse('hide');
      $('#company-name-collapse').collapse('hide');
    } else {
      $('#auto-address-collapse').collapse('hide');
      $('#auto-company-name-collapse').collapse('hide');
      $('#address-collapse').collapse('show');
      $('#company-name-collapse').collapse('show');
    }
  };
  $('[custom-id="address-collapser"]').on('change', updateAddressFormBasedOnCountry);
  $(document).on('popup', () => {
    $('[custom-id="address-collapser"]').on('change', updateAddressFormBasedOnCountry);
    updateAddressFormBasedOnCountry();
  });
  updateAddressFormBasedOnCountry();

  // show download status of file
  $('[data-download]').click(function (e) {
    const before = $(this).html();
    const url = $(this).attr('data-download');
    const redirectUrl = $(this).attr('data-redirect');

    $(this).html(`<i class="fal fa-spinner-third fa-spin"></i> ${translations.please_wait}...`);
    const that = this;
    const done = function () {
      $(that).html(`<i class="fa fa-check"></i> ${translations.download_completed}`);
      location.href = url;

      if (redirectUrl) {
        setTimeout(() => {
          window.location.href = redirectUrl;
        }, 8000);
      }
    };
    e.preventDefault();
    $.ajax({
      url: $(this).attr('href'),
    })
      .done(done)
      .fail(() => {
        $(that).html(`<i class="fa fa-trash"></i> ${translations.something_went_wrong}...`);
      });
  });

  // shareRegisterSale step
  const updateShareSaleStep = function () {
    if ($('#share_sale_hasSale').val() === YES) {
      $('#share-register-collapse').collapse('show');
    } else {
      $('#share-register-collapse').collapse('hide');
    }
  };
  updateShareSaleStep();
  $('#share_sale_hasSale').on('change', () => {
    updateShareSaleStep();
  });

  // shareRegisterInheritance step (collapsing based on ID of element)
  $('.collapsing-listener').each((index, element) => {
    const updateShareInheritanceStep = function () {
      if (element.getAttribute('to-collapse-id') !== null) {
        if ($(element).val() === YES) {
          $(`#${element.getAttribute('to-collapse-id')}`).collapse('show');
        } else {
          $(`#${element.getAttribute('to-collapse-id')}`).collapse('hide');
        }
      }
    };
    updateShareInheritanceStep();
    $(element).on('change', () => {
      updateShareInheritanceStep();
    });
  });

  // TakeOverStep
  const updateTakeoverStep = function () {
    if ($('#form_takeover').val() == YES) {
      $('#form_is_registered').closest('.form-group').css('display', 'block');
    } else {
      $('#form_is_registered').val('0');
      $('#form_is_registered').closest('.form-group').css('display', 'none');
      $('#form_zefix_search_zefix_search_label').css('display', 'none');
    }

    if ($('#form_is_registered').val() == YES) {
      $('#form_zefix_search_zefix_search_label').closest('.form-group').css('display', 'block');
      $('#form_zefix_search_zefix_search_label').css('display', 'block');
      $('#form_keep_name').closest('.form-group').css('display', 'block');
    } else {
      $('#form_zefix_search_zefix_search_label').closest('.form-group').css('display', 'none');
      $('#form_keep_name').val('0');
      $('#form_keep_name').closest('.form-group').css('display', 'none');
    }
  };
  updateTakeoverStep();
  $('#takeover_form').on('change', () => {
    updateTakeoverStep();
  });

  // Collections.JS <3
  $(document).ready(() => {
    $('.add-another-collection-widget').each((index, value) => {
      const addAnotherButton = $(value);

      const deleteCollectionItem = function (e) {
        e.preventDefault();
        $(this).closest('.form-group').fadeOut().remove();
      };

      const list = $(addAnotherButton.attr('data-list-selector'));

      // Try to find the counter of the list or use the length of the list
      let counter = list.data('widget-counter') || list.children().length;

      const addNewWidget = function () {
        // grab the prototype template
        let newWidget = list.attr('data-prototype');

        // replace the "__name__" used in the id and name of the prototype
        // with a number that's unique to your emails
        // end name attribute looks like name="contact[emails][2]"
        newWidget = $(newWidget.replace(/__name__/g, counter));

        // Increase the counter
        counter++;

        // And store it, the length cannot be used if deleting widgets is allowed
        list.data('widget-counter', counter);

        // create a new list element and add it to the list
        // let newElem = $('<div class="collection-item"></div>').html(newWidget);
        newWidget.appendTo(list);

        newWidget.find('.remove-collection-widget').on('click', deleteCollectionItem);
      };
      addAnotherButton.click(addNewWidget);

      $('.remove-collection-widget').each(function () {
        $(this).on('click', deleteCollectionItem);
      });
    });
  });
};

$(document).ready(() => {
  $('[data-dynamic-add]').each(function () {
    new DynamicAdd($(this));
  });

  // initialize popovers outside Vue components
  const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]');
  const popoverList = [...popoverTriggerList].map(
    (popoverTriggerEl) => new bootstrap.Popover(popoverTriggerEl)
  );

  // hide popover if clicked outside of the popover box
  document.documentElement.addEventListener('click', (event) => {
    if (!event.target.closest('.popover')) {
      const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]');
      popoverTriggerList.forEach((p) => {
        bootstrap.Popover.getInstance(p)?.hide();
      });
    }
  });

  $('[data-toggle="tooltip"]').tooltip();
  customAppFunctions();

  $(document).on('popup', () => {
    $('[data-toggle="tooltip"]').tooltip();
    customAppFunctions();
  });

  $(document).on('update', () => {
    $('[data-toggle="tooltip"]').tooltip();
    customAppFunctions();
  });
});
