enum ShabexFunction {
  PRAESIDENT = '1',
  NO_FUNCTION = '2',
  OBMANN = '3',
  VIZEPRAESIDENT = '5',
  DELEGIERTER = '7',
  SEKRETAER = '8',
  PROTOKOLLFUEHRER = '9',
  KASSIER = '11',
  RECHNUNGSFUEHRER = '12',
  AKTUAR = '13',
  QUAESTOR = '14',
  BEISITZER = '15',
  MITGLIED = '16',
  EINZIGES_MITGLIED = '18',
  INHABER = '19',
  KOMMANDITAER = '20',
  KOMPLEMENTAER = '21',
  UNBESCHRAENKT_HAFTENDER_GESELLSCHAFTER = '22',
  GESCHAEFTSFUEHRER = '23',
  GESELLSCHAFTER = '24',
  SUPPLEANT = '25',
  LIQUIDATOR = '27',
  KONKURS_LIQUIDATOR = '28',
  AUSSERAMTLICHER_KONKURSVERWALTER = '29',
  VERTRETER_DES_KOMPLEMENTAERS = '30',
  STELLVERTRETENDER_GESCHAEFTSFUEHRER = '31',
  SEKRETAER_NICHTMITGLIED = '32',
  PROTOKOLLFUEHRER_NICHTMITGLIED = '33',
  KASSIER_NICHTMITGLIED = '35',
  RECHNUNGSFUEHRER_NICHTMITGLIED = '36',
  AKTUAR_NICHTMITGLIED = '37',
  QUAESTOR_NICHTMITGLIED = '38',
  GENERALDIREKTOR = '39',
  STELLVERTRETENDER_GENERALDIREKTOR = '40',
  DIREKTOR = '41',
  STELLVERTRETENDER_DIREKTOR = '42',
  VIZEDIREKTOR = '43',
  SUBDIREKTOR = '44',
  MITGLIED_DER_DIREKTION = '46',
  VORSITZENDER_DER_DIREKTION = '48',
  VORSITZENDER_DER_GESCHAEFTSLEITUNG = '49',
  MITGLIED_DER_GESCHAEFTSLEITUNG = '50',
  BANKLEITER = '51',
  STELLVERTRETENDER_BANKLEITER = '52',
  GESCHAEFTSFUEHRER_NICHTMITGLIED = '53',
  LEITER = '55',
  GENERALSEKRETAER = '57',
  BEISTAND = '58',
  ZEICHNUNGSBERECHTIGTER = '59',
  PROKURIST = '60',
  REVISIONSSTELLE = '61',
  REVISIONSSTELLE_MIT_BEGRENZTEM_MANDAT = '62',
  REVISIONSSTELLE_GEMAESS_KAG = '63',
  GERICHTLICH_FESTGELEGTE_REVISIONSSTELLE = '64',
  ARBEITGEBERVERTRETER = '69',
  ARBEITNEHMERVERTRETER = '70',
  MITGLIED_DER_BANKLEITUNG = '87',
  VORSITZENDER_DER_BANKLEITUNG = '88',
  STELLVERTRETENDER_VORSITZENDER_DER_GESCHAEFTSLEITUNG = '90',
  PROVISORISCHER_SACHVERWALTER = '91',
  SACHVERWALTER = '92',
  VERWALTER = '94',
  STELLVERTRETENDER_VERWALTER = '95',
  VIZE_GENERALDIREKTOR = '96',
  STELLVERTRETENDER_VORSITZENDER_DER_BANKLEITUNG = '97',
  SPEZIALGESETZLICHER_REVISOR = '98',
  STELLVERTRETENDER_VORSITZENDER_DER_DIREKTION = '100',
  UNBEKANNTE_FUNKTION = '103',
  VERWALTER_NICHTMIGLIED = '106',
  VORSITZENDER_DER_GESCHAEFTSFUEHRUNG = '995',
  STELLVERTRETENDER_VORSITZENDER_DER_GESCHAEFTSFUEHRUNG = '996',
  GESELLSCHAFTER_UND_GESCHAEFTSFUEHRUNG = '997',
  NO_SIGNATURE = '1000',
}

export default ShabexFunction;
