// MutationOptionsEnum.php
enum MutationType {
  CHANGING_NAME = 'changing_name',
  CHANGING_SEAT = 'changing_seat',
  CHANGING_DOMICILE = 'changing_domicile',
  CHANGING_PURPOSE = 'changing_purpose',
  CHANGING_PERSON = 'changing_person',
  CHANGING_STATUTEN = 'changing_statuten',
  CHANGING_SHARE = 'changing_share',
  CHANGING_DELETE = 'changing_delete',
  SPECIAL = 'mutation_special',
}

export default MutationType;
