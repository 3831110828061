export function isEmpty<TValue>(value: TValue | null | undefined): value is null | undefined {
  return value === null || value === undefined;
}

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return !isEmpty(value);
}

export function floatFormat(num: number | string) {
  const intl = Intl.NumberFormat('de-CH', { minimumFractionDigits: 2, maximumFractionDigits: 6 });
  return intl.format(typeof num === 'string' ? parseFloat(num) : num);
}

export function standardFormat(num: number | string) {
  const intl = Intl.NumberFormat('de-CH', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
  return intl.format(typeof num === 'string' ? parseFloat(num) : num);
}

export function isObject<
  TValue = unknown,
  TKey extends string | number | symbol = string | number | symbol
>(value: unknown): value is Record<TKey, TValue> {
  return typeof value === 'object' && value !== null && !Array.isArray(value);
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
type Translations = Record<string, string | Translations>;

export function transformTranslationKeysToObjects(translations: Translations): Translations {
  const result: Translations = {};

  Object.entries(translations).forEach(([key, value]) => {
    const parts = key.split('.');
    let currentLevel = result;

    for (let i = 0; i < parts.length - 1; i++) {
      const part = parts[i];
      currentLevel[part] = currentLevel[part] || {};
      currentLevel = currentLevel[part] as Translations;
    }

    currentLevel[parts[parts.length - 1]] =
      typeof value === 'string' ? value : transformTranslationKeysToObjects(value);
  });

  return result;
}

export function groupBy<T>(arr: T[], groupingFn: (el: T) => string | number | symbol) {
  return arr.reduce((acc, value) => {
    const key = groupingFn(value);
    return {
      ...acc,
      [key]: (acc[key] ?? []).concat([value]),
    };
  }, {} as Record<string | number | symbol, T[]>);
}

export function optionalJoin(arr: (string | null | undefined)[], separator: string) {
  return arr.filter((part) => part).join(separator);
}
