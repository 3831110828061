/**
 * Used in process "IST-Overview" to show loading of process-data and update as soon as process-data has finished loading
 */
function processInitializationLoader() {
  const process_status_check = document.querySelectorAll('.process-status-check');
  let finished_steps = 0;
  const RETRY_INTERVAL = 2000;

  process_status_check.forEach((element) => {
    const process_url = element.dataset.url;
    let interval = null;

    let requesting = false;
    interval = setInterval(() => {
      if (!requesting) {
        requesting = true;
        fetch(process_url)
          .then((response) => response.json())
          .then((data) => {
            if (data.initialized) {
              ++finished_steps;
              if (finished_steps >= process_status_check.length) {
                clearInterval(interval);
                // DO NOT RELOAD HERE
                // as the reload of the full page is done inside the init_summary_process_overview.html.twig
                // because there might be more steps in the background that need to be filled
              } else if (data.content) {
                $(element).closest('.summary-row-coloring').html(data.content);
                clearInterval(interval);
              }
            }
          })
          .finally(() => {
            requesting = false;
          });
      }
    }, RETRY_INTERVAL);
  });
}

$(document).ready(processInitializationLoader);
