/*
 * If #select_switch is defined, depending on value of selection two divs are shown/hidden & viceversa
 */
$(document).ready(() => {
  const select = $('#select_switch');
  const divShowIfZero = $('#div_show_if_zero');
  const divShowIfNonZero = $('#div_show_if_non_zero');

  if (select.length && divShowIfZero.length) {
    const updateChoiceView = function () {
      // update view based on selection
      if (select.find(':selected').val() == 0) {
        divShowIfZero?.slideUp();
        divShowIfNonZero?.slideDown();
      } else {
        divShowIfZero?.slideDown();
        divShowIfNonZero?.slideUp();
      }
    };

    select.change(() => {
      updateChoiceView();
    });

    updateChoiceView();
  }
});
