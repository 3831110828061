import { set } from 'lodash-es';
// This returns the validation errors as nested object
export function useValidationErrors(error) {
  var fieldErrors = computed(function () {
    var unrefedError = unref(error);
    if (unrefedError instanceof UnprocessableEntityError) {
      var output = {};
      Object.keys(unrefedError.errors).forEach(function (key) {
        set(output, key, unrefedError.errors[key]);
      });
      return output;
    }
    return {};
  });
  var hasFieldErrors = computed(function () {
    var _fieldErrors$value;
    return Object.keys((_fieldErrors$value = fieldErrors.value) !== null && _fieldErrors$value !== void 0 ? _fieldErrors$value : {}).length > 0;
  });
  return {
    fieldErrors: fieldErrors,
    hasFieldErrors: hasFieldErrors
  };
}