let autocompletes = [];

function initializeAutoComplete() {
  autocompletes = [];

  $(this)
    .find('[data-autocomplete]')
    .each((index, element) => {
      const $dataInput = $(element);
      const $searchInput = $(`#${$dataInput[0].id}_label`);
      $searchInput.on('focus', () => {
        $searchInput.attr('readonly', false);
      });
      const $autocompleteSearchGroup = $searchInput.parent('.autocomplete-input-group');
      const $spinner = $autocompleteSearchGroup.find('.autocomplete-spinner');

      const autocomplete = $searchInput.autocomplete({
        showNoSuggestionNotice: true,
        noSuggestionNotice: translations.autocomplete_no_result,
        tooManyResultsNotice: translations.autocomplete_too_many_results,
        notConnectedToZefixNotice: translations.autocomplete_not_connected_to_zefix,
        deferRequestBy: 300,
        minChars: 3,
        serviceUrl: $dataInput.data('searchUrl'),
        onSelect(suggestion) {
          $dataInput.val(suggestion.data);
          $dataInput.trigger('change');
          this.value = suggestion.value.replace(/\s+\([^(]+\)$/, '');
        },
        onSearchStart() {
          $spinner.addClass('show');
        },
        onSearchComplete() {
          $spinner.removeClass('show');
        },
      });

      autocompletes.push(autocomplete);
    });
}

function destroyAutoComplete() {
  $('.autocomplete-suggestions').remove();
  initializeAutoComplete();
}

$(document).ready(initializeAutoComplete);
$(document).on('update', initializeAutoComplete);
$(document).on('popup', initializeAutoComplete);
$(document).on('remove_popup', destroyAutoComplete);
