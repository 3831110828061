import { watch } from 'vue';
export function useFormErrorMessage(_ref) {
  var name = _ref.name,
    error = _ref.error,
    resetter = _ref.watch,
    _ref$disableHide = _ref.disableHide,
    disableHide = _ref$disableHide === void 0 ? false : _ref$disableHide;
  var form = inject('form', undefined);
  var show = ref(false);
  var computedError = computed(function () {
    var unrefedError = unref(error);
    if (unrefedError) {
      return unrefedError;
    }
    var unrefedName = unref(name);
    if (unrefedName && form !== null && form !== void 0 && form.value.fieldErrors[unrefedName]) {
      return form.value.fieldErrors[unrefedName];
    }
    return null;
  });
  var message = computed(function () {
    return show.value || unref(disableHide) ? computedError.value : null;
  });
  watch(computedError, function (newError) {
    show.value = !!newError;
  }, {
    immediate: true
  });
  watch(resetter, function () {
    show.value = false;
  });
  return {
    show: show,
    message: message
  };
}