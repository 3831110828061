function initNewJurPerson() {
  const $autocompleteValue = $('#jur_person_zefixSearch_zefixSearch');

  if ($autocompleteValue.length > 0) {
    const $autocompleteInput = $('#jur_person_zefixSearch_zefixSearch_label');
    const $companyNameInput = $('#jur_person_companyName');
    const $uidInput = $('#jur_person_uid');
    const $plzInput = $('#jur_person_seat_plz');
    const $streetInput = $('#jur_person_streetAddress');
    const $coInput = $('#jur_person_coAddress');
    const $hrPopover = $('#hr_popover');

    const hrLinkSelector = '%hr_auszug_link%';
    const originalHrPopoverText = $hrPopover.data('content');

    // TODO: Intl
    const defaultHrLink = 'https://www.zefix.ch/de/search/entity/welcome';

    const $modal = $('#main_modal').find('.modal-dialog');
    $modal.append(
      '<div class="spinner-border" id="modal_spinner" role="status" style="width: 3rem; height: 3rem; display: none;"><span class="sr-only">Loading...</span></div>'
    );
    const $spinner = $('#modal_spinner');
    $spinner.css('position', 'absolute');
    $spinner.css('top', '10%');
    $spinner.css('left', '50%');

    $modal.find('.modal-content').find('.modal-body').css('opacity', '1');
    $spinner.hide();

    if (originalHrPopoverText != null) {
      $hrPopover.attr('data-content', originalHrPopoverText.replace(hrLinkSelector, defaultHrLink));

      $autocompleteInput.on('input', () => {
        $companyNameInput.val($autocompleteInput.val());
        $hrPopover.attr(
          'data-content',
          originalHrPopoverText.replace(hrLinkSelector, defaultHrLink)
        );
      });
    }

    $autocompleteValue.change(function () {
      $modal.find('.modal-content').find('.modal-body').css('opacity', '0.3');
      $spinner.show();

      $.ajax({
        type: 'GET',
        url: SHABEX_COMPANY_DETAILS,
        data: {
          id: $(this).val(),
        },
        success(data) {
          $companyNameInput.val(data.name);

          $uidInput.val(data.uid);
          $plzInput.val(data.address.plz);

          const ortInputText = $('[data-ort]');
          ortInputText.val(data.address.city);

          const $ortInput = $('[data-ort-select="seat"]');
          $ortInput.html(`<option value="${data.address.city}">${data.address.city}</option>`);
          $ortInput.val(data.address.city);

          const ortInputSitz = $('[data-sitz]');
          ortInputSitz.val(data.legalSeat);

          const $sitzInput = $('[data-sitz-select="seat"]');
          $sitzInput.html(`<option value="${data.legalSeat}">${data.legalSeat}</option>`);
          $sitzInput.val(data.legalSeat);

          $.ajax({
            dataType: 'json',
            url: '/plz/request_kanton',
            data: { seat: data.legalSeat },
            success(data) {
              const $kantonInput = $('[data-kanton="seat"]');
              $kantonInput.val(data.result.kanton);
            },
          });

          let streetAddress = data.address.street;
          if (data.address.houseNumber !== null) {
            streetAddress += ` ${data.address.houseNumber}`;
          }
          $streetInput.val(streetAddress);

          $coInput.val(data.address.careOf);

          if (originalHrPopoverText != null) {
            $hrPopover.attr(
              'data-content',
              originalHrPopoverText.replace(hrLinkSelector, defaultHrLink)
            );
          }

          $modal.find('.modal-content').find('.modal-body').css('opacity', '1');
          $spinner.hide();
        },
      });
    });
  }
}

$(document).ready(initNewJurPerson);
$(document).on('update', initNewJurPerson);
$(document).on('popup', initNewJurPerson);
