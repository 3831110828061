// ProcessTypeEnum.php
enum ProcessType {
  foundingAg = 'founding_ag',
  foundingGmbh = 'founding_gmbh',
  foundingSole = 'founding_sole',
  foundingBranch = 'founding_branch',
  foundingBranchAg = 'founding_branch_ag',
  foundingBranchGmbh = 'founding_branch_gmbh',
  // Does not actually exist, but refers to the special processes that are through Zoho Forms
  foundingSpecial = 'founding_special',

  mutationAg = 'mutation_ag',
  mutationGmbh = 'mutation_gmbh',
  mutationSole = 'mutation_sole',
  mutationBranchAg = 'mutation_branch_ag',
  mutationBranchGmbh = 'mutation_branch_gmbh',
}

export default ProcessType;
