let locale = '';

export default function useCachedLocale() {
  function getCachedLocale(): string {
    if (locale !== '') {
      return locale;
    }

    const path = window.location.pathname;
    if (path.match(/^\/de\/?./)) {
      locale = 'de';
    } else if (path.match(/^\/fr\/?./)) {
      locale = 'fr';
    } else if (path.match(/^\/en\/?./)) {
      locale = 'en';
    } else if (path.match(/^\/it\/?./)) {
      locale = 'it';
    }

    return locale ?? 'de';
  }

  // TODO clear locale on route-change (as soon as we actually support multiple languages and use the vue router :))

  return {
    getCachedLocale,
  };
}
