// copied from PermissionVoter.php
export enum VoterTypes {
  START_FOUNDING = 'start-founding',
  START_BRANCH_FOUNDING = 'start-branch-founding',
  START_SPECIAL_FOUNDING = 'start-special-founding',
  START_BRANCH_MUTATION = 'start-branch-mutation',
  START_AG_MUTATION = 'start-ag-mutation',
  START_GMBH_MUTATION = 'start-gmbh-mutation',
  START_SOLE_MUTATION = 'start-sole-mutation',
  HAS_PERMISSION = 'has-permission',
  HAS_ALL_OF_PERMISSIONS = 'has-all-permissions',
  HAS_ONE_OF_PERMISSIONS = 'has-one-of-permissions',
}

export function isGrantedPermissions(
  attribute: VoterTypes,
  subject: string | string[],
  trusteePermissions: string[]
): boolean {
  switch (attribute) {
    case VoterTypes.START_FOUNDING:
      return typeof subject === 'string' && trusteePermissions.includes(subject);
    case VoterTypes.START_BRANCH_FOUNDING:
      return ['founding_branch_ag', 'founding_branch_gmbh'].some((permission) =>
        trusteePermissions.includes(permission)
      );
    case VoterTypes.START_SPECIAL_FOUNDING:
      return ['founding_ag_special', 'founding_gmbh_special'].some((permission) =>
        trusteePermissions.includes(permission)
      );
    case VoterTypes.START_BRANCH_MUTATION:
      return (
        typeof subject === 'string' &&
        [`mutation_branch_ag_${subject}`, `mutation_branch_gmbh_${subject}`].some((permission) =>
          trusteePermissions.includes(permission)
        )
      );
    case VoterTypes.START_AG_MUTATION:
      return typeof subject === 'string' && trusteePermissions.includes(`mutation_ag_${subject}`);
    case VoterTypes.START_GMBH_MUTATION:
      return typeof subject === 'string' && trusteePermissions.includes(`mutation_gmbh_${subject}`);
    case VoterTypes.START_SOLE_MUTATION:
      return typeof subject === 'string' && trusteePermissions.includes(`mutation_sole_${subject}`);
    case VoterTypes.HAS_PERMISSION:
      return typeof subject === 'string' && trusteePermissions.includes(subject);
    case VoterTypes.HAS_ALL_OF_PERMISSIONS:
      return (
        Array.isArray(subject) &&
        subject.every((permission) => trusteePermissions.includes(permission))
      );
    case VoterTypes.HAS_ONE_OF_PERMISSIONS:
      return (
        Array.isArray(subject) &&
        subject.some((permission) => trusteePermissions.includes(permission))
      );
    default:
      return false;
  }
}
