import { Qes } from '@/interfaces/Qes';

export interface InvolvedPersonViewModel {
  id: number;
  token: string;
  personId: number;
  academicTitle: string;
  name: string;
  functions: string[];
  signature: string;
  isSigning: boolean;
  isSignatureMandatory: boolean;
  mobilePhone: string;
  isMobilePhoneQes: boolean;
  email: string;
}

/**
 * Map QES phone number checks onto phone numbers of InvolvedPersonViewModels directly in signers array
 */
export function mapQesToSignersOfInvolvedPersonViewModel(
  signers: InvolvedPersonViewModel[],
  data: Qes[]
): void {
  signers.forEach((signer) => {
    // eslint-disable-next-line no-param-reassign
    signer.isMobilePhoneQes = !!data.find((qes) => qes.mobilePhone === signer.mobilePhone)
      ?.isMobilePhoneQes;
  });
}

/**
 * Returning mobile phone numbers of signers in one flattened array for easier processing
 * @param signers
 */
export function getMobilePhoneOfInvolvedPersonViewModel(
  signers: InvolvedPersonViewModel[]
): Array<{ mobilePhone: string }> {
  return signers.flatMap((signer) =>
    signer.mobilePhone ? [{ mobilePhone: signer.mobilePhone }] : []
  );
}
