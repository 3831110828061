import * as Vue from 'vue'
import ProcessTable from '../../components/ProcessTable'
import CompanyView from '../../components/companyView/CompanyView'
import PersonOrigins from '../../components/natPersonForm/PersonOrigins'
import SignatureImage from '../../components/natPersonForm/SignatureImage'
import AcademicTitleImage from '../../components/natPersonForm/AcademicTitleImage'
import SeatStep from '../../components/seatStep/SeatStep';
import SignatureStep from '../../components/signatureStep/SignatureStep'
import GfVrProtocolStep from '../../components/gfVrProtocolStep/GfVrProtocolStep'
import Profile from '../../components/Profile'
import TrusteeIndex from '../../components/TrusteeIndex'
import ServicesView from '../../components/servicesView/ServicesView'
import IdentityImage from '../../components/natPersonForm/IdentityImage'
import Seat from '../../components/natPersonForm/Seat'
import StatisticsView from '../../components/StatisticsView'
import TrusteeApproval from '@/components/processStatus/TrusteeApproval.vue'
import TrusteeRelease from '@/components/processStatus/TrusteeRelease.vue'
import SignDocuments from '@/components/SignDocuments'
import UploadSignature from '@/components/UploadSignature'
import GvProtocolStep from '@/components/gvProtocolStep/GvProtocolStep.vue'
import SignatureSubstitutesStep from '@/components/signatureSubstitutesStep/SignatureSubstitutesStep'
import EditMutationOptions from '@/components/processStep/EditMutationOptions.vue'
import { VueQueryPlugin } from '@tanstack/vue-query'
import { i18nPromise } from '@/js/admin-vue/i18n'
import CommonInfoStep from '@/components/commonInfoStep/CommonInfoStep.vue'
import { isAdminRouteProviderKey } from '@/js/providers/isAdminRouteProvider'
import { queryFn } from '@/js/composables/useQueryFn'
import TwigProcessStepInvolvedNatPersonForm from '@/components/twigWrapper/TwigProcessStepInvolvedNatPersonForm.vue'
import TwigProcessStepNatPersonForm from '@/components/twigWrapper/TwigProcessStepNatPersonForm.vue'
import TwigJurPersonSignerForm from '@/components/twigWrapper/TwigJurPersonSignerForm.vue'
import TwigProcessStepJurPersonForm from '@/components/twigWrapper/TwigProcessStepJurPersonForm.vue'

/* Implement sentry for vue, when everything is vue
import * as Sentry from "@sentry/vue";
Sentry.init({
  Vue,
  dsn: "https://c5eddcada340e3ad0e1a90db01953218@o4505673844785152.ingest.sentry.io/4505673853829120",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      //tracePropagationTargets: ["localhost", "https:yourserver.io/api/"],
      //routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
 */

const app = {};

export function renderModalElements() {
  // put elements here, so that they are evaluated during runtime only when the modal is opened and the payload was loaded
  const modalElements = [
    // needed until JurPersonSigner and InvPerson are implemented in Vue
    {
      selector: '#vue-person-origins',
      component: { PersonOrigins },
      props: {
        personId: document.getElementById('vue-person-origins')?.dataset.personId ?? 0,
        fileToken: document.getElementById('vue-person-origins')?.dataset.fileToken,
        personFormPrefix:
          document.getElementById('vue-person-origins')?.dataset.personFormPrefix?.trim() ||
          'nat_person',
        token: document.getElementById('vue-person-origins')?.dataset.token,
        processId: document.getElementById('vue-person-origins')?.dataset.processId ?? 0,
        isESignature:
          'true' === document.getElementById('vue-person-origins')?.dataset.isESignature,
      },
    },
    // needed until JurPersonSigner and InvPerson are implemented in Vue
    {
      selector: '#vue-seat',
      component: { Seat },
      props: {
        isSwitzerland: 'true' === document.getElementById('vue-seat')?.dataset.isSwitzerland,
        seatAsJson: document.getElementById('vue-seat')?.dataset.seatAsJson,
      },
    },
    {
      selector: '#vue-signature-image',
      component: { SignatureImage },
      props: {
        fileToken: document.getElementById('vue-signature-image')?.dataset.fileToken,
        token: document.getElementById('vue-signature-image')?.dataset.token,
      },
    },
    {
      selector: '#vue-upload-academic-title',
      component: { AcademicTitleImage },
      props: {
        token: document.getElementById('vue-upload-academic-title')?.dataset.token,
      },
    },
    {
      selector: '#vue-twig-process-step-involved-nat-person-form',
      component: { TwigProcessStepInvolvedNatPersonForm },
      props: {
        fileToken: document.getElementById('vue-twig-process-step-involved-nat-person-form')
          ?.dataset.fileToken,
        processId:
          parseInt(
            document.getElementById('vue-twig-process-step-involved-nat-person-form')?.dataset
              .processId
          ) || 0,
        isESignature:
          'true' ===
          document.getElementById('vue-twig-process-step-involved-nat-person-form')?.dataset
            .isESignature,
        stepId: document.getElementById('vue-twig-process-step-involved-nat-person-form')?.dataset
          .stepId,
        invPersonId:
          parseInt(
            document.getElementById('vue-twig-process-step-involved-nat-person-form')?.dataset
              .invPersonId
          ) || undefined,
        eSignUrl: document.getElementById('vue-twig-process-step-involved-nat-person-form')?.dataset
          .eSignUrl,
        isHeadquarterPerson:
          'true' ===
          document.getElementById('vue-twig-process-step-involved-nat-person-form')?.dataset
            .isHeadquarterPerson,
        violations: JSON.parse(
          document.getElementById('vue-twig-process-step-involved-nat-person-form')?.dataset
            .violations ?? '{}'
        ),
      },
    },
    {
      selector: '#vue-twig-process-step-nat-person-form',
      component: { TwigProcessStepNatPersonForm },
      props: {
        fileToken: document.getElementById('vue-twig-process-step-nat-person-form')?.dataset
          .fileToken,
        processId:
          parseInt(
            document.getElementById('vue-twig-process-step-nat-person-form')?.dataset.processId
          ) || 0,
        isESignature:
          'true' ===
          document.getElementById('vue-twig-process-step-nat-person-form')?.dataset.isESignature,
        stepId: document.getElementById('vue-twig-process-step-nat-person-form')?.dataset.stepId,
        natPersonId:
          parseInt(
            document.getElementById('vue-twig-process-step-nat-person-form')?.dataset.natPersonId
          ) || undefined,
        eSignUrl: document.getElementById('vue-twig-process-step-nat-person-form')?.dataset
          .eSignUrl,
        furtherPayload: document.getElementById('vue-twig-process-step-nat-person-form')?.dataset
          .furtherPayload,
        violations: JSON.parse(
          document.getElementById('vue-twig-process-step-nat-person-form')?.dataset.violations ??
            '{}'
        ),
      },
    },
    {
      selector: '#vue-twig-process-step-jur-person-form',
      component: { TwigProcessStepJurPersonForm },
      props: {
        fileToken: document.getElementById('vue-twig-process-step-jur-person-form')?.dataset.fileToken,
        processId: parseInt(document.getElementById('vue-twig-process-step-jur-person-form')?.dataset.processId) || 0,
        stepId: document.getElementById('vue-twig-process-step-jur-person-form')?.dataset.stepId,
        jurPersonId: parseInt(document.getElementById('vue-twig-process-step-jur-person-form')?.dataset.jurPersonId) || undefined,
        violations: JSON.parse(document.getElementById('vue-twig-process-step-jur-person-form')?.dataset.violations ?? '{}'),
        isESignature: 'true' === document.getElementById('vue-twig-process-step-jur-person-form')?.dataset.isESignature,
        eSignUrl: document.getElementById('vue-twig-process-step-jur-person-form')?.dataset.eSignUrl,
        furtherPayload: document.getElementById('vue-twig-process-step-jur-person-form')?.dataset
          .furtherPayload,
      },
    },
    {
      selector: '#vue-twig-jur-person-signer-form',
      component: { TwigJurPersonSignerForm },
      props: {
        fileToken: document.getElementById('vue-twig-jur-person-signer-form')?.dataset.fileToken,
        jurPersonId:
          parseInt(
            document.getElementById('vue-twig-jur-person-signer-form')?.dataset.jurPersonId
          ) || 0,
        isESignature:
          'true' ===
          document.getElementById('vue-twig-jur-person-signer-form')?.dataset.isESignature,
        jurPersonSignerId:
          parseInt(
            document.getElementById('vue-twig-jur-person-signer-form')?.dataset.jurPersonSignerId
          ) || undefined,
        eSignUrl: document.getElementById('vue-twig-jur-person-signer-form')?.dataset.eSignUrl,
        returnTo: document.getElementById('vue-twig-jur-person-signer-form')?.dataset.returnTo,
      },
    },
  ];

  for (const modalElement of modalElements) {
    const elements = document.querySelector('#main_modal').querySelectorAll(modalElement.selector);

    if (elements.length > 0) {
      for (const element of elements) {
        if (app[modalElement.selector]) {
          app[modalElement.selector].unmount();
        }

        let autoProps = modalElement?.props;
        if ((modalElement?.autoProps ?? false) && null == autoProps) {
          autoProps = { ...element.dataset };
        }

        app[modalElement.selector] = Vue.createApp(
          {
            components: modalElement.component,
            template: modalElement.template ?? `<${Object.keys(modalElement.component)[0]}/>`,
          },
          autoProps
        );

        app[modalElement.selector].provide(isAdminRouteProviderKey, false);

        app[modalElement.selector].use(VueQueryPlugin, {
          queryClientConfig: {
            defaultOptions: { queries: { queryFn: queryFn(), refetchOnWindowFocus: false } },
          },
        });

        app[modalElement.selector].mount(element);
      }
    }
  }
}

// leave this in here for now as reference:
// function render(hostElement) {
//     const isPagination = hostElement.dataset.pagination;
//     app = Vue.createApp({
//         components: { ProcessTable },
//         template: `<ProcessTable :is-paginated="${isPagination}"></ProcessTable>`
//     });
//     app.mount(hostElement);
//
//     // add all other components:
//     app.component('PaginatedTable', PaginatedTable);
// }

export function render() {
  const hostElements = [
    {
      id: 'vue-trustee-index',
      component: { TrusteeIndex },
      props: {
        startType: document.getElementById('vue-trustee-index')?.dataset.processTypes,
      },
    },
    {
      id: 'vue-process-table',
      component: { ProcessTable },
      props: {
        startType: document.getElementById('vue-process-table')?.dataset.startType,
      },
    },
    {
      id: 'vue-company-view',
      component: { CompanyView },
      props: {
        hasFreeProcess:
          'true' === document.getElementById('vue-company-view')?.dataset.hasFreeProcess,
      },
    },
    {
      id: 'vue-seat-step',
      component: { SeatStep },
      props: {
        processId: parseInt(document.getElementById('vue-seat-step')?.dataset.processId) || 0,
        fileToken: document.getElementById('vue-seat-step')?.dataset.fileToken,
        isESignature: 'true' === document.getElementById('vue-seat-step')?.dataset.isESignature,
        eSignUrl: document.getElementById('vue-seat-step')?.dataset.eSignUrl,
        nextStepUrl: document.getElementById('vue-seat-step')?.dataset.nextStepUrl,
        initialErrors: JSON.parse(document.getElementById('vue-seat-step')?.dataset.initialErrors ?? '[]'),
      },
    },
    {
      id: 'vue-signature-step',
      component: { SignatureStep },
      props: {
        processId: parseInt(document.getElementById('vue-signature-step')?.dataset.processId),
        hasSignatureTypeChoice:
          'true' === document.getElementById('vue-signature-step')?.dataset.hasSignatureTypeChoice,
        initialIsESignature:
          'true' === document.getElementById('vue-signature-step')?.dataset.initialIsESignature,
      },
    },
    {
      id: 'vue-signature-substitutes-step',
      component: { SignatureSubstitutesStep },
      props: {
        processId: parseInt(
          document.getElementById('vue-signature-substitutes-step')?.dataset.processId
        ),
      },
    },
    {
      id: 'vue-gf-vr-protocol-step',
      component: { GfVrProtocolStep },
      props: {
        processId: parseInt(document.getElementById('vue-gf-vr-protocol-step')?.dataset.processId),
        companyType: document.getElementById('vue-gf-vr-protocol-step')?.dataset.companyType,
      },
    },
    {
      id: 'vue-gv-protocol-step',
      component: { GvProtocolStep },
      props: {
        processId: parseInt(document.getElementById('vue-gv-protocol-step')?.dataset.processId),
        sharesType: document.getElementById('vue-gv-protocol-step')?.dataset.sharesType,
      },
    },
    {
      id: 'vue-user-profile',
      component: { Profile },
    },
    {
      id: 'vue-services-view',
      component: { ServicesView },
      props: {
        permissions: JSON.parse(
          document.getElementById('vue-services-view')?.dataset.permissions ?? '[]'
        ),
      },
    },
    {
      id: 'vue-identity-image',
      component: { IdentityImage },
      props: {
        nationality: document.getElementById('vue-identity-image')?.dataset.nationality,
        hometown: document.getElementById('vue-identity-image')?.dataset.hometown,
        identificationMethod:
          document.getElementById('vue-identity-image')?.dataset.identificationMethod,
        uploadOnly: 'true' === document.getElementById('vue-identity-image')?.dataset.uploadOnly,
        token: document.getElementById('vue-identity-image')?.dataset.token,
      },
    },
    {
      id: 'vue-upload-signature',
      component: { UploadSignature },
      props: {
        fileToken: document.getElementById('vue-upload-signature')?.dataset.fileToken,
        getUrl: document.getElementById('vue-upload-signature')?.dataset.getUrl,
        deleteUrl: document.getElementById('vue-upload-signature')?.dataset.deleteUrl,
        uploadUrl: document.getElementById('vue-upload-signature')?.dataset.uploadUrl,
        eSignUrl: document.getElementById('vue-upload-signature')?.dataset.eSignUrl,
        processId: parseInt(document.getElementById('vue-upload-signature')?.dataset.processId),
        token: document.getElementById('vue-upload-signature')?.dataset.token,
        signatureAlreadyApproved:
          'true' ===
          document.getElementById('vue-upload-signature')?.dataset.signatureAlreadyApproved,
        personName: document.getElementById('vue-upload-signature')?.dataset.personName,
      },
    },
    {
      id: 'vue-upload-academic-title',
      component: { AcademicTitleImage },
      props: {
        token: document.getElementById('vue-upload-academic-title')?.dataset.token,
        uploadOnly:
          'true' === document.getElementById('vue-upload-academic-title')?.dataset.uploadOnly,
      },
    },
    {
      id: 'vue-statistics-graph',
      component: { StatisticsView },
    },
    {
      id: 'vue-trustee-approval',
      component: { TrusteeApproval },
      props: {
        processId: parseInt(document.getElementById('vue-trustee-approval')?.dataset.processId),
      },
    },
    {
      id: 'vue-trustee-release',
      component: { TrusteeRelease },
      props: {
        processId: parseInt(document.getElementById('vue-trustee-release')?.dataset.processId),
        initialTrusteeEmail:
          document.getElementById('vue-trustee-release')?.dataset.initialTrusteeEmail,
        delayedDocsWithDateJson:
          document.getElementById('vue-trustee-release')?.dataset.delayedDocsWithDateJson,
      },
    },
    {
      id: 'vue-sign-documents',
      component: { SignDocuments },
      props: {
        mesoneerSmartLink: document.getElementById('vue-sign-documents')?.dataset.mesoneerSmartLink,
        trusteeName: document.getElementById('vue-sign-documents')?.dataset.trusteeName,
      },
    },
    {
      id: 'vue-edit-mutation-options',
      component: { EditMutationOptions },
      props: {
        processId: parseInt(
          document.getElementById('vue-edit-mutation-options')?.dataset.processId
        ),
        permissions: JSON.parse(
          document.getElementById('vue-edit-mutation-options')?.dataset.permissions ?? '[]'
        ),
        processType: document.getElementById('vue-edit-mutation-options')?.dataset.processType,
        incorrectInitStepId: document.getElementById('vue-edit-mutation-options')?.dataset
          .incorrectInitStepId,
      },
    },
    {
      id: 'vue-common-info-step',
      component: { CommonInfoStep },
      props: {
        processId: parseInt(document.getElementById('vue-common-info-step')?.dataset.processId),
      },
    },
  ];

  for (const hostElement of hostElements) {
    const element = document.getElementById(hostElement.id);
    if (element) {
      if (app[hostElement.id]) {
        app[hostElement.id].unmount();
      }
      let autoProps = hostElement?.props;
      if ((hostElement?.autoProps ?? false) && null == autoProps) {
        autoProps = { ...element.dataset };
      }

      app[hostElement.id] = Vue.createApp(
        {
          components: hostElement.component,
          template: hostElement?.template ?? `<${Object.keys(hostElement.component)[0]}/>`,
        },
        autoProps
      );

      app[hostElement.id].provide(isAdminRouteProviderKey, false);

      app[hostElement.id].use(VueQueryPlugin, {
        queryClientConfig: {
          defaultOptions: { queries: { queryFn: queryFn(), refetchOnWindowFocus: false } },
        },
      });

      app[hostElement.id].mount(element);
    }
  }
}

$(document).ready(() => {
  i18nPromise.then((i18n) => {
    render();
  });
});
