import i18n from '@/js/admin-vue/i18n';

/**
 *
 * @param value currency in Rappen
 */
export const currencyCHF = (value: number): string => {
  let result = (value / 100.0).toLocaleString('de-CH', {
    style: 'currency',
    currency: 'CHF',
  });
  // in case of negative value, there is no space between CHF and '-' but there should be one
  return result.replace('CHF-', 'CHF -');
};

/**
 * @param fileName - Name of the file where translations are stored
 * @param key - Key of the translation
 * @param valuesToReplace - Replace values by key inside translated string
 * @deprecated use translate from translate.ts
 */
export const translate = (
  fileName: string,
  key: string,
  valuesToReplace: Record<string, string | number> | null = null
) => {
  let translationValue = i18n.global.t(`${fileName}.${key}`, valuesToReplace ?? {});

  if (valuesToReplace) {
    for (const [key, value] of Object.entries(valuesToReplace)) {
      if (value !== undefined && value !== null) {
        translationValue = translationValue.replace(key, value.toString());
      }
    }
  }
  // eslint-disable-next-line consistent-return
  return translationValue;
};

/**
 *
 * @param dateTime - Date and time in ISO format
 * @param includeTime - If true, time will be included in the output
 */
export const formatDateTime = (dateTime: string | number | Date, includeTime: boolean = false) => {
  if (dateTime === '') return '';
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };
  const dateTimeObject = new Date(dateTime);
  let result = dateTimeObject.toLocaleDateString('de-CH', options);
  if (includeTime) {
    result += ` ${dateTimeObject.toLocaleTimeString('de-CH').substring(0, 5)}`;
  }
  return result;
};
