// JS
require('jquery');
require('bootstrap');
require('@popperjs/core');
require('flatpickr');
require('@fortawesome/fontawesome-pro/js/all');
require('cookieconsent/build/cookieconsent.min.js');

require('./frontend/app.js');
require('./frontend/table_filter.js');
require('./frontend/new_jur_person.js');
require('./frontend/process_status_check.js');
require('./shared/choice_switch_form.js');
require('./shared/modal.js');
require('./shared/file_upload.js');
require('./shared/tooltips.js');
require('./shared/cookie.js');
require('./shared/plz.js');
require('./shared/choice_other.js');
require('./shared/autocomplete.js');
require('./shared/autocomplete_type.js');
require('./shared/datatable.js');
require('./frontend/vue_components.js');

// (S)CSS
require('../bootstrap/_custom.scss');
require('../scss/shared/_bootstrap_replacement.scss');
require('../scss/shared/_transitions.scss');
require('../scss/shared/main.scss');
require('../scss/shared/modal.scss');
require('../scss/shared/file_upload.scss');
require('../scss/shared/flashes.scss');
require('../scss/shared/autocomplete.scss');
require('../scss/shared/ring_loader.scss');
require('../scss/frontend/layout.scss');
require('../scss/frontend/footer.scss');
require('../scss/frontend/progress.scss');
require('../scss/frontend/main.scss');
require('cookieconsent/build/cookieconsent.min.css');
require('@fortawesome/fontawesome-pro/scss/fontawesome.scss');
const Sentry = require('@sentry/vue');
const Vue = require('vue');
