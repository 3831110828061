const { renderModalElements, render } = require('../frontend/vue_components');
window.bootstrap = require('bootstrap');

const modalFormSelector = '#main_modal form';
const $mainModal = $('#main_modal');
let forceClose = false;
let no_confirmation_needed = false;
const $document = $(document);

$('body').on('click', '.modal_link,[data-popup="form"]', modalLinkClicked);
$('body').on('click', '[data-popup="form-no-confirm-on-close"]', modalLinkNoConfirmOnCloseClicked);

// confirm on close
function addDefaultEventListener() {
  $mainModal.on('hide.bs.modal', () => {
    if (!forceClose && !no_confirmation_needed) {
      if (confirm(translations.confirmation_modal_close)) {
        $(document).trigger('remove_popup');
        return true;
      }
      return false;
    }
    $(document).trigger('remove_popup');
  });
}
$mainModal.off();
addDefaultEventListener();

/**
 * Toggles, resets and loads modal for request.
 *
 * @param {string}  url
 * @param {element} targetElement
 */
function loadModal(url, targetElement) {
  const appendClass = $(targetElement).data('appendClass');
  if (appendClass) $mainModal.find('div.modal-dialog').addClass(appendClass);
  forceClose = false;
  $mainModal.modal('show');
  resetModal();
  $.ajax({
    type: 'GET',
    url,
    data: {},
    success(data) {
      $(modalFormSelector).first().unbind('submit');
      setModalContent(findTitle(data), findContent(data), true, () => {
        if ($(`${modalFormSelector} #submit`).length) {
          initialButtonText = $(`${modalFormSelector} #submit span`).eq(0).text();
          initialButtonType = $(`${modalFormSelector} #submit span`)
            .eq(0)
            .attr('class')
            .replace('submit-', '');
          toggleBindQuickSubmit(true);
        }
        $(modalFormSelector).first().on('submit', { target: targetElement }, ajaxFormHandler);
        renderModalElements();
        // Initialize Everything
        // used to make popovers work with bootstrap 5
        const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]');
        const popoverList = [...popoverTriggerList].map(
          (popoverTriggerEl) => new bootstrap.Popover(popoverTriggerEl)
        );
      });
    },
    error(xhr) {
      switch (xhr.status) {
        case 401:
          window.location.reload(true);
          break;
        case 403: // (Invalid CSRF token or no access for example)
          noAccessModal();
          break;
        case 500:
          internalServerErrorModal();
      }
    },
  });
}

/**
 * Path events of target element, if has data-event defined.
 *
 * @param {element} target
 */
function patchEvent(target) {
  const $target = $(target);
  if (typeof $target.data('event') !== 'undefined') {
    const event = new CustomEvent($target.data('event'), {
      detail: $target.data('payload'),
    });
    document.dispatchEvent(event);
  }
}

/**
 * Event handler when modal link with no confirm on close is clicked
 *
 * @param event
 */
function modalLinkNoConfirmOnCloseClicked(event) {
  no_confirmation_needed = true;

  event.preventDefault();
  const url = $(this).attr('href');
  loadModal(url, event.currentTarget);
}

/**
 * Event handler when modal link is clicked.
 *
 * @param {object} event
 */
function modalLinkClicked(event) {
  no_confirmation_needed = false;

  event.preventDefault();
  const url = $(this).attr('href');
  loadModal(url, event.currentTarget);
}

/**
 * Toggles the event binding of the quick submit.
 *
 * @param {boolean} active
 */
function toggleBindQuickSubmit(active) {
  if (active) {
    $document.bind('keypress.quick', (event) => {
      if (event.ctrlKey) {
        $(modalFormSelector).eq(0).submit();
      }
    });
  } else if (!active) {
    $document.unbind('keypress.quick');
  }
}

/**
 * Gets title by selector.
 *
 * @param {string} htmlString
 *
 * @return {jQuery}
 */
function findTitle(htmlString) {
  return $(htmlString).closest('.page-title').text();
}

/**
 * Gets content by selector.
 *
 * @param {string} htmlString
 *
 * @return {jQuery}
 */
function findContent(htmlString) {
  return $(htmlString).closest('main').html();
}

/**
 * Resets modal.
 */
function resetModal() {
  setModalContent('LOADING', translations.please_wait, false);
}

/**
 * Shows 403 - Access denied modal.
 */
function noAccessModal() {
  setModalContent('403 - ACCESS DENIED', translations.access_denied, false);
}

/**
 * Shows 500 - internal server error modal.
 */
function internalServerErrorModal() {
  setModalContent('500 - INTERNAL SERVER ERROR', translations.internal_error, false);
}

/**
 *
 * @param {string|jQuery}   title
 * @param {string|jQuery}   body
 * @param {boolean}  animate
 * @param {function|null} callback
 */
function setModalContent(title, body, animate = false, callback = null) {
  $mainModal.find('.modal-title').html(title);

  if (animate) {
    smoothPlace(
      $mainModal.find('.modal-body'),
      $mainModal.find('.modal-body .ajax-loader'),
      body,
      () => {
        if (typeof callback === 'function') {
          callback();
        }
        $document.trigger('popup');
      }
    );
  } else {
    $mainModal.find('.modal-body .ajax-loader').html(body);
    if (typeof callback === 'function') {
      callback();
    }
    $document.trigger('popup');
  }
}

/**
 * Handles the ajax form submission.
 *
 * @param {object} event
 */
function ajaxFormHandler(event) {
  event.preventDefault();
  const node = $(this);
  toggleBindQuickSubmit(false);
  if (node.hasClass('submitting') === true) {
    return;
  }
  node.addClass('submitting');
  setButtonState('loading');
  $.ajax({
    type: node.attr('method'),
    url: node.attr('action'),
    data: node.serialize(),
    success(data, status, xhr) {
      const ct = xhr.getResponseHeader('content-type') || '';
      if (ct.indexOf('json') > -1) {
        // TODO make a better modal interface
        if (data.reload_url) {
          $.ajax({
            type: 'GET',
            url: data.reload_url,
            data: {},
            success(data) {
              $(modalFormSelector).eq(0).unbind('submit');
              node.closest('.page-content-wrapper').html(data);
              $(modalFormSelector).eq(0).on('submit', ajaxFormHandler);
            },
          });
        } else if (data.redirect_url) {
          window.location = data.redirect_url;
        } else if (data.status === 'success') {
          $('#main_modal').addClass('success');
          setButtonState('success');
          setTimeout(() => {
            forceClose = true;
            node.closest('.modal').modal('toggle');
            $('#main_modal').removeClass('success');
          }, 1000);
          patchEvent(event.data ? event.data.target : event.target);
          updatePage();
        } else {
          setButtonState('error');
          setModalContent('ERROR', data.status, true);

          // render vue components
          renderModalElements();
        }
      } else {
        $(modalFormSelector).eq(0).unbind('submit');
        setModalContent(findTitle(data), findContent(data), false);
        setButtonState('error');
        $(modalFormSelector).eq(0).on('submit', ajaxFormHandler);

        // render vue components
        renderModalElements();
      }
    },
    error() {
      node.removeClass('submitting');
    },
  });
}

/**
 * Updated the page.
 */
function updatePage() {
  $.ajax({
    type: 'GET',
    url: location.href,
    data: {},
    success(data) {
      $(document).trigger('before_update');
      $(data)
        .find('[data-update]')
        .each(function () {
          $(`[data-update="${$(this).attr('data-update')}"]`).html($(this).html());
        });
      $(document).trigger('update');
      render();
    },
  });
}

function forceCloseModal() {
  forceClose = true;
  $('#main_modal').modal('hide');
}

let initialButtonText = 'action.save';
let initialButtonType = 'primary';

/**
 * Sets the button state.
 *
 * @param {string} state
 */
function setButtonState(state) {
  if (state === 'loading') {
    transitionButton(
      `${translations.loading}<i class="pull-right fal fa-spinner-third fa-spin"></i>`,
      'default'
    );
  } else if (state === 'success') {
    transitionButton(`${translations.success}<i class="pull-right fal fa-check"></i>`, 'success');
  } else if (state === 'error') {
    transitionButton(
      `${translations.error}<i class="pull-right fal fa-exclamation-circle"></i>`,
      'danger'
    );
    // resetAlert();
  } else if (state === 'reset') {
    transitionButton(initialButtonText, initialButtonType);
  }
}

/**
 * Add transition effect to buttons.
 *
 * @param {string} message
 * @param {string} type
 */
function transitionButton(message, type) {
  const ANIMATION_SPEED = 300;
  const button = $(`${modalFormSelector} #submit`);
  const old = button.find('span');
  const height = old.outerHeight();
  button.append(`<span class="submit-${type}"><div style="opacity: 1">${message}</div></span>`);

  // This is needed to force redraw, so that the DOM updates after the append()
  $(window).trigger('resize');

  button
    .find('span div')
    .eq(0)
    .animate(
      {
        opacity: '0',
      },
      ANIMATION_SPEED * 0.5
    );
  button
    .find('span div')
    .eq(1)
    .animate(
      {
        opacity: '1',
      },
      ANIMATION_SPEED * 0.5
    );
  button.find('span').animate(
    {
      top: `-${height}px`,
    },
    ANIMATION_SPEED,
    function () {
      $(this).css({
        top: '0px',
      });
      old.remove();
    }
  );
}

/**
 * Resets alert.
 */
function resetAlert() {
  if ($(`${modalFormSelector} .alert-danger`).length) {
    $(modalFormSelector).on('focus', 'input, select, textarea', function () {
      setButtonState('reset');
      $(`${modalFormSelector} .alert`).slideUp();
      $(this).unbind('focus');
    });
  }
}

/**
 * Adds a smooth place feature.
 *
 * @param {element}  holder
 * @param {element}  loader
 * @param {element|jQuery}  data
 * @param {function} callBack
 */
function smoothPlace(holder, loader, data, callBack = null) {
  // Set not initialized parameter
  const speed = typeof speed === 'undefined' ? 250 : speed;

  const $loader = $(loader);
  const $holder = $(holder);

  // get height
  const oldHeight = $holder.outerHeight();
  $holder.css('height', oldHeight);

  // things happen
  $loader.fadeOut('fast', () => {
    $loader.css('position', 'absolute');
    $loader.html(data);

    // set height
    const newHeight = $loader.outerHeight();
    $holder.animate({ height: newHeight }, speed, () => {
      // fade in
      $loader.css('position', 'static');
      $loader.fadeIn();
      $holder.css('height', 'auto');

      if (typeof callBack === 'function') {
        callBack();
      }
    });
  });
}

export default { updatePage: () => updatePage(), forceCloseModal: () => forceCloseModal() };
