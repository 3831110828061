require('datatables.net-bs4');
require('datatables.net-bs4/css/dataTables.bootstrap4.css');

$('a[data-toggle="tab"]').on('shown.bs.tab', (e) => {
  $($.fn.dataTable.tables()).DataTable().fixedHeader.adjust();
});

const transformIntegerToHour = function (i) {
  let hours = Math.floor(i / 60);
  if (hours <= 9) {
    hours = `0${hours}`;
  }
  let minutes = i % 60;
  if (minutes <= 9) {
    minutes = `0${minutes}`;
  }
  return `${hours}:${minutes}`;
};

// Remove the formatting to get integer data for summation
const intVal = function (i) {
  return typeof i === 'string' ? i.replace(/[\$,]/g, '') * 1 : typeof i === 'number' ? i : 0;
};

const dataTables = [];

function destroyDataTables() {
  dataTables.forEach((element) => {
    element.destroy();
  });
  dataTables.length = 0;
}

function initializeDataTables() {
  const $simpleDataTables = $('.simple-data-table');

  const options = {};
  if (LANGUAGE !== 'en') {
    options.language = require(`../datatable_lang/${LANGUAGE}.js`);
  }

  $simpleDataTables.each((index, element) => {
    const $dataTable = $(element).DataTable(options);

    dataTables.push($dataTable);
  });
}

$(document).ready(() => {
  // set our custom grid
  const DataTable = $.fn.dataTable;
  $.extend(true, DataTable.defaults, {
    dom:
      "<'row'<'col-sm-24 col-md-12'l><'col-sm-24 col-md-12'f>>" +
      "<'row'<'col-sm-24'tr>>" +
      "<'row'<'col-sm-24 col-md-10'i><'col-sm-24 col-md-14'p>>",
    renderer: 'bootstrap',
  });

  initializeDataTables();
});

$(document).on('before_update', destroyDataTables);
$(document).on('update', initializeDataTables);
