import i18n from '@/js/admin-vue/i18n';

export function translate(
  key: string,
  valuesToReplace?: Record<string, string | number> | null | undefined
): string;
export function translate(
  fileName: string,
  key: string,
  valuesToReplace?: Record<string, string | number> | null | undefined
): string;
export function translate(
  fileName: string,
  key?: string | Record<string, string | number> | null | undefined,
  valuesToReplace?: Record<string, string | number> | null | undefined
): string {
  /* eslint-disable no-param-reassign */
  if (typeof key === 'object' || key === null || key === undefined) {
    valuesToReplace = key;

    const split = fileName.split('.');
    fileName = split.shift() ?? '';
    key = split.join('.');
  }

  let translationValue = i18n.global.t(`${fileName}.${key}`, valuesToReplace ?? {});

  if (valuesToReplace) {
    Object.entries(valuesToReplace).forEach(([replaceKey, replaceValue]) => {
      if (replaceValue !== undefined && replaceValue !== null) {
        translationValue = translationValue.replace(replaceKey, replaceValue.toString());
      }
    });
  }

  return translationValue;
}
